import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import './footer.css';

const Footer = () => {
  const footerRef = useRef();
  const [showSuccess, setShowSuccess] = useState();
  const [ emailReq, setEmailReq ] = useState({
    book_firstName: "",
    book_lastName: "",
    book_email: "",
    book_company: "",
  });

  const sendJoinWaitingList = () => {

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YXBpOmUzMzRiYmRhYmUzMTg5ZjZhYjIyYjliOWUyYzdkZWRkLTY5MjEwY2ZjLTJmMTQ1ZTlj");
    
    let formdata = new FormData();
    formdata.append("from", "Carbon Zero <postmaster@mg.carbonzeroit.co.uk>");
    formdata.append("to", `Carbon Zero <hello@carbonzeroit.co.uk>`);
    formdata.append("subject", "Carbon Zero IT: Join our Waiting List");
    formdata.append("template", "carbon-zero-welcome");
    formdata.append("h:X-Mailgun-Variables", "{\"book_firstName\": \"" + emailReq.book_firstName + "\",\"book_lastName\": \"" + emailReq.book_lastName + "\",\"book_email\": \"" + emailReq.book_email + "\",\"book_company\": \"" + emailReq.book_company + "\"}");
    
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://api.eu.mailgun.net/v3/mg.carbonzeroit.co.uk/messages", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result);
        setShowSuccess(true);
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    if (window.location.href.split('#')[window.location.href.split('#').length - 1] === 'join-waiting-list') {
      footerRef.current.scrollIntoView();
    }
  }, []);

  return (
  <footer className="home-footer" ref={footerRef}>
    <div className="footer-inner">
      <div id="join-waiting-list" className="get-in-touch">
        <form className="get-in-touch-inner" onSubmit={(e)=>{
          sendJoinWaitingList();
          e.preventDefault();
        }}>
          <p>We only have one planet</p>
          <h2>Book a call back</h2>
          <div className="form-group one-line-mobile">
            <label>Firstname</label>
            <input value={emailReq.book_firstName} onChange={(e)=>{
              setEmailReq({
                ...emailReq,
                book_firstName: e.target.value
              })
            }} required/>
          </div>
          <div className="form-group one-line-mobile">
            <label>Lastname</label>
            <input value={emailReq.book_lastName} onChange={(e)=>{
              setEmailReq({
                ...emailReq,
                book_lastName: e.target.value
              })
            }} required/>
          </div>
          <div className="form-group one-line-mobile">
            <label>Email</label>
            <input type={'email'} value={emailReq.book_email} onChange={(e)=>{
              setEmailReq({
                ...emailReq,
                book_email: e.target.value
              })
            }} required/>
          </div>
          <div className="form-group one-line-mobile">
            <label>Company</label>
            <input value={emailReq.book_company} onChange={(e)=>{
              setEmailReq({
                ...emailReq,
                book_company: e.target.value
              })
            }} required/>
          </div>
          <br/>
          <div className="form-group check-wrap">
            <div className="checkbox-wrapper"><input type={'checkbox'} required/></div>
            <label>By ticking this box, you to agree to our Privacy Policy and Terms & Conditions.</label>
          </div>
          <br/>
          <br/>
          <br/>
          { showSuccess ? 
            <p>Success! You're now on our waiting list.</p> : 
            <button type="submit">SUBMIT</button>
          }
          {/* <div className="form-group">
            <input type={'radio'} />
            <label>Request API acces</label>
          </div>
          <br/>
          <div className="form-group">
            <label>Or book in an appointment with Freddie</label>
          </div>
          <br/>
          <div className="profile">
            <img width={100} src="https://d365pq86x330zn.cloudfront.net/3l810889-6306-4686-0111-1336101lE608.png" />
            <div className="details">
              <h4>Freddie Savundra</h4>
              <label>Lead Carbon Consultant</label>
            </div>
          </div> */}
        </form>
        <div className="img-wrapper">
          <img src="https://d365pq86x330zn.cloudfront.net/81681163-605g-4o26-604l-422888l48l46.png" />
        </div>
      </div>
      <br/><br/><br/>
      <div className="bottom-part">
        <div className="company">
          <img src="https://d365pq86x330zn.cloudfront.net/r55617r6-4656-4455-5191-56677665699r.png" width={500} /><br/><br/>
          <h5>Technology Driven, Carbon Netural IT Solutions</h5>
        </div>
        <div className="footer-links">
          <Link to="/cookie-policy">Cookie Policy</Link><br/><br/>
          <Link to="/privacy-policy">Privacy Policy</Link><br/><br/>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </div>
      </div>
      <br/>
    </div>
  </footer>
  )
};

export default Footer;