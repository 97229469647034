import { Link } from "react-router-dom";

import './content.css';

const Content = () => (
  <main className="terms-and-conditions">
    <div className="terms-and-conditions-inner">
      <div className="title-wrapper">
        <h1>Terms and Condition Policy</h1>
      </div>

      <br/><br/><br/><br/>

      <h2>Introduction</h2>

      <br/><br/>

      <p>This website is owned and maintained by CarbonX IT Ltd. We are registered in England and Wales under company number 14411406 and have our registered office at 8 Haling Road, Croydon, CR2 6HS. You can contact us at this address, by email at <a heref="mailto:hello@carbonzeroit.co.uk">hello@carbonzeroit.co.uk</a> .</p><br/>

      <br/><br/>

      <h2>Use of our site and additional terms</h2>

      <br/><br/>

      <p>By using this website, you agree to be legally bound by these terms and conditions. If you do not accept these terms and conditions, please do not use this website.</p><br/>

      <p>We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. Our site is directed to people residing in England and Wales. The content is not appropriate or for use by people in other locations.</p><br/>

      <p>These terms and conditions also refer to the following additional terms, which also apply to your use of our site:</p><br/>

      <ol className="number">
        <li>
            <p>Our <Link to="/privacy-policy">Privacy Policy</Link></p>
        </li>
        <li>
            <p>Our <Link to="/cookie-policy">Cookie Policy</Link></p>
        </li>
      </ol><br/>

      <br/><br/>

      <h2>Your account details</h2>

      <br/><br/>

      <p>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat this as confidential material. You must not reveal it to any third party.</p><br/>

      <p>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p><br/>

      <p>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a href="mailto:hello@carbonzeroit.co.uk">hello@carbonzeroit.co.uk</a>.</p><br/>

      <br/><br/>

      <h2>Use of material on our site</h2>

      <br/><br/>

      <p>We are the owner or the licensee of all intellectual property rights in our site. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p><br/>

      <p>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way.</p><br/>

      <p>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p><br/>

      <p>You must not use any part of the content on our site for commercial purposes without obtaining a license to do so from us or our licensors.</p><br/>

      <p>If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p><br/>

      <br/><br/>

      <h2>Information on this site</h2>

      <br/><br/>

      <p>The information contained or displayed on this site is believed to be reliable when displayed. Whilst reasonable care has been taken to ensure that the information and functions contained or displayed on this website are complete and correct, we do not warrant, represent or make any guarantee whether express or implied that the content of our site is accurate, complete, free from errors or omissions or up to date.</p><br/>

      <br/><br/>

      <h2>Third party websites and links</h2>

      <br/><br/>

      <p>Our website may from time to time contain links to other websites and resources provided by third parties. We have no control over and accept no responsibility for the content of such linked sites and they are provided for your information only. We have not approved linked websites or information you may obtain from them and have no control over the content of those sites or resources.</p><br/>

      <br/><br/>

      <h2>Our liability</h2>

      <br/><br/>

      <ul>
        <li>
            <p>Please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
        </li>
        <li>
            <p>If defective digital content that we have supplied, damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</p>
        </li>
      </ul><br/>

      <br/><br/>

      <h2>Use of your personal information</h2>

      <br/><br/>

      <p>We will only use your personal information as set out in our Privacy Policy.</p><br/>

      <p>We may contact you by mail, telephone, SMS text, or email about products or services which may be of interest to you. You will be given an opportunity to indicate whether you wish to receive these communications or not. You will also be given the opportunity to select your marketing preferences on our site or on the App.</p><br/>

      <p>Alternatively, if you decide that you do not want to receive marketing messages at any point please write to <a href="mailto:hello@carbonzeroit.co.uk">hello@carbonzeroit.co.uk</a> and we will ensure that your request is complied with.</p><br/>

      <br/><br/>

      <h2>Computer viruses</h2>

      <br/><br/>

      <p>We are not responsible for viruses and you must not introduce them.</p><br/>

      <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p><br/>

      <p>You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</p><br/>

      <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p><br/>

      <br/><br/>

      <h2>Rules about linking to our site</h2>

      <br/><br/>

      <p>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p><br/>

      <p>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p><br/>

      <p>You must not establish a link to our site in any website that is not owned by you.</p><br/>

      <p>Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p><br/>

      <p>We reserve the right to withdraw linking permission without notice.</p><br/>

      <p>If you wish to link to or make any use of content on our site other than that set out above, please contact <a href="mailto:hello@carbonzeroit.co.uk">hello@carbonzeroit.co.uk</a>.</p><br/>

      <p>These terms and conditions, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction.</p><br/>

      <p>CarbonZeroIT is Registered Trademark of CarbonX IT Ltd.</p><br/>
    </div>
  </main>
);

export default Content;