import { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/home/home";
import CookiePolicy from "./pages/cookie-policy/cookie-policy";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import TermsAndConditions from "./pages/terms-and-conditions/terms-and-conditions";
import NotFound from "./pages/not-found/not-found";

import ScrollToTop from './hooks/ScrollToTop';

import './App.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
