import { Link } from "react-router-dom";

import './footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="footer-inner">
      <div className="bottom-part">
        <div className="company">
          <img src="https://d365pq86x330zn.cloudfront.net/r55617r6-4656-4455-5191-56677665699r.png" width={500} /><br/><br/>
          <h5>Technology Driven, Carbon Netural IT Solutions</h5>
        </div>
        <div className="footer-links">
          <Link to="/cookie-policy">Cookie Policy</Link><br/><br/>
          <Link to="/privacy-policy">Privacy Policy</Link><br/><br/>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </div>
      </div>
      <br/>
    </div>
  </footer>
);

export default Footer;