import { Link } from "react-router-dom";

import './content.css';

const Content = () => (
  <main className="cookie-policy">
    <div className="cookie-policy-inner">
      <div className="title-wrapper">
        <h1>Cookie Policy</h1>
      </div>

      <br/><br/><br/><br/>

      <p>What cookies do we use? We use the following cookies:</p><br/>

      <br/><br/>

      <h2>Targeting cookies</h2>

      <br/><br/>

      <p>Targeting cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests.</p><br/>

      <br/><br/>

      <h2>Necessary cookies</h2>

      <br/><br/>

      <p>These cookies are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website such as your account or to use our calculator.</p><br/>

      <br/><br/>

      <h2>Analysis cookies</h2>

      <br/><br/>

      <p>These cookies allow us to recognise and count the number of visitors and to see how users move around our website when they are using it. This helps us analyse and improve the performance of our website and to ensure that users and finding what they are looking for easily. They also help us improve the website by measuring any errors that occur.</p><br/>

      <br/><br/>

      <h2>Third party cookies</h2>

      <br/><br/>

      <p>There may also be third parties which provide us with content and functionality on our website, some of whom will use cookies, over which we have no control. These third parties may include, for example, advertising networks and providers of services like web traffic analysis. Third party cookies are likely to be analytical or performance cookies or targeting cookies. To deactivate the use of third-party advertising cookies, you may visit Settings and manage the use of these type of cookies.</p><br/>

      <br/><br/>

      <h2>Your control over cookies</h2>

      <br/><br/>

      <p>By using our site, you accept our Cookie policy. You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you block all cookies (including necessary ones), you may not be able to access all or parts of our website. You may refuse to accept cookies by altering the settings on your internet browser but in some cases by doing so may impact your ability to use our website or certain parts of it.</p><br/>
    </div>
  </main>
);

export default Content;