import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Content from './content/content';

const CookiePolicy = () => (
  <main>
    <Header />
    <Content />
    <Footer />
  </main>
);

export default CookiePolicy;