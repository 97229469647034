import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import * as localForage from "localforage";

import './header.css';

const Header = () => { 
  const [menuToggle, setMenuToggle] = useState(false);
  const [isAcceptCookies, setIsAcceptCookies] = useState('');

  const acceptCookies = () => {
    localForage.setItem('cookies', 'yes').then(function () {
      return localForage.getItem('cookies');
    }).then(function (newValue) {
      if (newValue === 'yes') {
        setIsAcceptCookies('yes');
      }
    })  
  }

  const init = () => {
    localForage.getItem('cookies').then(function (value) {
      if (value !== null) {
        if (value === 'yes') {
          setIsAcceptCookies('yes');
        }
      }
    });
  }

  useEffect(() => {
    init();
  }, []);
  return (<>
  <header className="header">
    <nav className={menuToggle ? "nav show" : "nav"}>
      <div className="logo-wrapper">
        <Link to="/">
          <img className="logo" alt="Carbon Zero Logo" src="https://d365pq86x330zn.cloudfront.net/r88o1118-1581-48r8-8481-1855467G1486.png" />
        </Link>
        <svg className="toggle" onClick={()=>void setMenuToggle(!menuToggle)} width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.8854 12.3422H4.11458C3.56805 12.3422 3.125 12.8049 3.125 13.3757V13.4844C3.125 14.0552 3.56805 14.5179 4.11458 14.5179H20.8854C21.4319 14.5179 21.875 14.0552 21.875 13.4844V13.3757C21.875 12.8049 21.4319 12.3422 20.8854 12.3422Z" fill="#DFDFDA"/>
          <path d="M20.8854 17.7816H4.11458C3.56805 17.7816 3.125 18.2443 3.125 18.8151V18.9239C3.125 19.4947 3.56805 19.9574 4.11458 19.9574H20.8854C21.4319 19.9574 21.875 19.4947 21.875 18.9239V18.8151C21.875 18.2443 21.4319 17.7816 20.8854 17.7816Z" fill="#DFDFDA"/>
          <path d="M20.8854 6.90283H4.11458C3.56805 6.90283 3.125 7.36554 3.125 7.93632V8.04511C3.125 8.61589 3.56805 9.0786 4.11458 9.0786H20.8854C21.4319 9.0786 21.875 8.61589 21.875 8.04511V7.93632C21.875 7.36554 21.4319 6.90283 20.8854 6.90283Z" fill="#DFDFDA"/>
        </svg>
      </div>
      <div className="links-wrapper">
        <Link to="/" className="active">Home</Link>
        <a href="/#join-waiting-list">Contact Us</a>
        <Link style={{opacity: 0, pointerEvents: 'none'}} to="/">Use Cases</Link>
        {/* <Link style={{opacity: 0, pointerEvents: 'none'}} to="/">Carbon Dashboard</Link> */}
      </div>
      <div className="buttons-wrapper" style={{opacity: 0, pointerEvents: 'none'}}>
        <Link className="book-a-demo-btn" to="#book-a-demo">Book A Demo</Link>
        <Link to="/">Login</Link>
      </div>
    </nav>
  </header>
  { !isAcceptCookies ?
  <div className="cookie-note">
    <div className="cookie-note-details">
      <h4>Cookies.</h4>
      <p>This site uses cookies to offer you a better browsing experience. <Link to="/cookie-policy">Find out more how we use cookies</Link>.</p>
    </div>
    <button onClick={()=>acceptCookies()}>I accept cookies</button>
  </div>
  : null }
  </>);
};

export default Header;