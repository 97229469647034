import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import ReactTypingEffect from 'react-typing-effect';
import Fade from 'react-reveal/Fade';
import './content.css';

const Content = () => {
  return (<main className="home">
    <section className="banner">
      <div className="banner-inner">
        <div className="details">
          <div className="details-inner">
            <h1 style={{minHeight: '288px', display: 'flex', alignItems: 
        'center'}} data-test="carbon-zero"><strong>Carbon Neutral <span>Managed</span> <ReactTypingEffect typingDelay={300} speed={33} eraseSpeed={33} eraseDelay={3000} text={["IT Services", "Cyber Security", "IT Support", "IT Solutions"]} /></strong></h1>
            <p>Technology driven, Carbon Neutral IT Solutions. So you can focus on running your Business.</p>
            <p><a href="#join-waiting-list" className="btn">Contact our Team</a></p>
          </div>
          <div className="img-wrapper">
            <div className="img">
              <img src="https://d365pq86x330zn.cloudfront.net/o367g16l-1o0o-4ogo-3511-157123371lo1.png" />
              <iframe className="video" src="https://www.youtube.com/embed/dtFpa1YsCKY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-bottom">
        <div className="banner-bottom-inner">
          <div className="logo-wrapper">
            <img src="https://d365pq86x330zn.cloudfront.net/4a24MM69-2116-41Ma-0sa0-02186sM2222s.png"/>
          </div>
          <div className="logo-wrapper">
            <img src="https://d365pq86x330zn.cloudfront.net/86612628-1261-4s11-7s87-9s8M66120M26.png"/>
          </div>
          <div className="logo-wrapper">
            <img src="https://d365pq86x330zn.cloudfront.net/8B16986M-6M6B-4666-3M63-632M82616M63.png"/>
          </div>
          <div className="logo-wrapper">
            <img src="https://d365pq86x330zn.cloudfront.net/66776c97-8981-4672-7777-MMc66822i732.png"/>
          </div>
          <div className="logo-wrapper">
            <img src="https://d365pq86x330zn.cloudfront.net/3116O323-a983-4rO8-1O81-66a919692OO3.png"/>
          </div>
        </div>  
      </div>
    </section>
    <section className="how-it-works">
      <div className="how-it-works-inner">
        <Fade left>
        <h2>Technology First - Carbon Neutral IT Solutions</h2>
        <p>Upgrading your IT is one of the most efficient ways you can help in the fight against Climate Change. Here’s how it works:</p>
        </Fade>
        <Fade left>
        <div className="list">
          <img height={45} width={45} src="https://d365pq86x330zn.cloudfront.net/7a148000-6a06-400a-0654-410446871001.png" />
          <p>We modernize, streamline and transform your IT Setup.</p>
        </div>
        <div className="list">
          <img height={45} width={45} src="https://d365pq86x330zn.cloudfront.net/4311ee6H-4711-4814-4847-6346aH8HH414.png" />
          <p>Our AI tools manage everything from updating your servers, protecting <br/>your data, to setting up new users and password resets.</p>
        </div>
        <div className="list">
          <img height={45} width={45} src="https://d365pq86x330zn.cloudfront.net/148468r7-668A-44A2-8814-r4r872414A16.png" />
          <p>We continually monitor and calculate the carbon footprint for your IT Setup, offsetting the balance with Carbon Credits.</p>
        </div>
        </Fade>
      </div>
    </section>
    <section className="carbon-zero-dash">
      <div className="carbon-zero-dash-inner">
        <Fade bottom>
        <img src="https://d365pq86x330zn.cloudfront.net/r5446814-188G-4o11-0020-141r8018o215.png" />
        </Fade>
      </div>
    </section>
    <section className="whop">
      <div className="whop-inner">
        <h2>We only have one planet.</h2>
        <div className="airplane">
          <Fade left>
          <img src="https://d365pq86x330zn.cloudfront.net/78361rG7-97r1-4434-16r9-87o333613o16.png" />
          </Fade>
        </div>
        <div className="nature">
          <Fade left>
          <div className="nature-inner">
            <img src="https://d365pq86x330zn.cloudfront.net/44967GG7-6466-4614-7G44-o41o1r67r984.png" />
          </div>
          </Fade>
          <Fade right>
          <div className="nature-inner">
            <p>
              <em>As cloud storage increases, so too does the cloud’s environmental footprint. With tech sector energy consumption at unprecedented levels, the rapid expansion of cloud computing means that by 2025, the technology sector could consume 20% of the world’s total electricity. It is currently at 7%....</em><br/><br/>
              Greenpeace, November 2022
            </p>
          </div>
          </Fade>
        </div>
      </div>
    </section>
    <section className="footplan">
      <div className="footplan-inner">
        <h2>How we calculate your Footprint</h2>
        <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
            <div className="plans">
              <p>
                <strong>+{isVisible ? <CountUp end={500} /> : null}</strong>
                Companies Integrated
              </p>
              <p>
                <strong>{isVisible ? <CountUp end={10} /> : null}</strong>
                Sectors Represented
              </p>
              <p>
                <strong>+{isVisible ? <CountUp end={100} /> : null}</strong>
                Action Plans
              </p>
              <p>
                <strong>{isVisible ? <><CountUp end={7} />.<CountUp end={9} /></> : null}</strong>
                Weeks Average Deployment
              </p>
            </div>
          )}
        </VisibilitySensor>
      </div>
    </section>
    <section className="ai-suggest">
      <div className="ai-suggest-inner">
        <h2>Our Services</h2>
        <br/>
        <div className="suggests">
          <Fade left>
          <div className="suggest">
            <img src="https://d365pq86x330zn.cloudfront.net/6311361e-1366-4ceR-4c54-514745R51575.png"/>
            <div className="details">
              <h5>Digital Transformation</h5>
              <p>Helping your company on their Digital Transformation Journey.</p>
            </div>
          </div>
          <div className="suggest">
            <img src="https://d365pq86x330zn.cloudfront.net/46223R6e-7126-4164-3R14-R53324c1R356.png"/>
            <div className="details">
              <h5>Green Cloud Solutions</h5>
              <p>We provide green energy powered solutions.</p>
            </div>
          </div>
          <div className="suggest">
            <img src="https://d365pq86x330zn.cloudfront.net/66351696-6c91-4Rc6-9153-666R39c37733.png"/>
            <div className="details">
              <h5>Virtual Desktop Solutions</h5>
              <p>So your team can work from anywhere on any device.</p>
            </div>
          </div>
          </Fade>
          <Fade right>
          <div className="suggest">
            <img src="https://d365pq86x330zn.cloudfront.net/c833eRR9-6662-47R6-8c84-863338656e64.png"/>
            <div className="details">
              <h5>Cyber Security</h5>
              <p>We protect your business and your data, with AI monitoring.</p>
            </div>
          </div>
          <div className="suggest">
            <img src="https://d365pq86x330zn.cloudfront.net/R3Rc1e76-5334-416c-5745-ec336765e415.png"/>
            <div className="details">
              <h5>Data Science</h5>
              <p>SQL, Python and other Data Analysis tools.</p>
            </div>
          </div>
          <div className="suggest">
            <img src="https://d365pq86x330zn.cloudfront.net/399c9R36-9R79-4946-6R96-647964313946.png"/>
            <div className="details">
              <h5>Carbon Monitoring</h5>
              <p>Real-time monitoring and tracking of your Carbon Footprint.</p>
            </div>
          </div>
          </Fade>
        </div>
        <br/><br/>
        <h2>What are the next steps?</h2>
        <br/><br/>
        <div className="cost">
          <Fade left>
          <div className="cost-inner">
            <img width={72} height={72} src="https://d365pq86x330zn.cloudfront.net/16334636-5683-4G64-5oor-585542345r46.png"/>
            <h3>Book a call with our Team</h3>
          </div>
          </Fade>
          <Fade right>
          <div className="cost-inner">
            <img width={72} height={72} src="https://d365pq86x330zn.cloudfront.net/288448o6-4r56-4816-64oo-8o8128888o26.png"/>
            <h3>We provide a no obligation quote</h3>
          </div>
          </Fade>
        </div>
        <br/><br/>
        <br/><br/>
        <label>No matter the scale, complexity or nature of your cloud deployment.</label>
        {/* <br/><br/>
        <br/><br/>
        <p>Our goal is to make carbon footprinting accessible to companies of all sizes. That's why our pricing can be adapted to companies with as few as 10 employees, and quickly-growing enterprises as well.</p> */}
        <br/><br/>
        <br/><br/>
        <a href="#join-waiting-list" className="btn">Contact our Team</a>
        <br/><br/>
      </div>
    </section>
    <section className="offset">
      <div className="offset-inner">
        <h2>How we offset your footprint</h2>
        <h3>From planting trees to UNICEF action plans.</h3>

        <div className="plans">
          <div className="plan yellow">
            <h4>Starter Package</h4>
            <h5>Take your first steps into climate action with this plan.</h5>

            <div className="consist">
              <img src="https://d365pq86x330zn.cloudfront.net/e80075c8-c675-4668-3180-617395R76731.png" width={100} />
              <div>
                <h6>8 trees funded</h6>
                <label>every month</label>
              </div>
            </div>

            <div className="consist">
              <img src="https://d365pq86x330zn.cloudfront.net/R17177c1-R145-4601-3741-c131711761R1.png" width={100} />
              <div>
                <h6>3 tonnes CO2</h6>
                <label>avoided every year (~2x footprint*)</label>
              </div>
            </div>

            <a href="#join-waiting-list" className="btn yellow">Choose plan</a>
          </div>
          <div className="plan green">
            <h4>Carbon Reduction Package</h4>
            <h5>The perfect plan to help you give back to the planet.</h5>

            <div className="consist">
              <img src="https://d365pq86x330zn.cloudfront.net/e80075c8-c675-4668-3180-617395R76731.png" width={100} />
              <div>
                <h6>8 trees funded</h6>
                <label>every month</label>
              </div>
            </div>

            <div className="consist">
              <img src="https://d365pq86x330zn.cloudfront.net/R17177c1-R145-4601-3741-c131711761R1.png" width={100} />
              <div>
                <h6>9 tonnes CO2</h6>
                <label>avoided every year (~2x footprint*)</label>
              </div>
            </div>

            <a href="#join-waiting-list" className="btn yellow">Choose plan</a>
          </div>
          <div className="plan red">
            <h4>Carbon Neutral Package</h4>
            <h5>Double your positive impact for the planet with this plan.</h5>

            <div className="consist">
              <img src="https://d365pq86x330zn.cloudfront.net/e80075c8-c675-4668-3180-617395R76731.png" width={100} />
              <div>
                <h6>15 trees funded</h6>
                <label>every month</label>
              </div>
            </div>

            <div className="consist">
              <img src="https://d365pq86x330zn.cloudfront.net/R17177c1-R145-4601-3741-c131711761R1.png" width={100} />
              <div>
                <h6>18 tonnes CO2</h6>
                <label>avoided every year (~2x footprint*)</label>
              </div>
            </div>

            <a href="#join-waiting-list" className="btn yellow">Choose plan</a>
          </div>
        </div>

        <p>* Footprints are for illustrative purposes only. At Ecologi, we believe that funding climate solutions is vital, but it doesn't diminish your own carbon emissions - and should therefore be carried out alongside steps you take to reduce your own footprint. As part of your subscription, we will fund the prevention or avoidance of approximately 1/3 (Starter), 1 (Essential) or 2 (Ultimate) footprints' worth of carbon emissions, during each year you're subscribed.</p>
      </div>
    </section>
  </main>);
};

export default Content;