import { Link } from "react-router-dom";

import './content.css';

const Content = () => (
  <main className="privacy-policy">
    <div className="privacy-policy-inner">
      <div className="title-wrapper">
        <h1>Privacy Policy</h1>
      </div>

      <br/><br/><br/><br/>

      <h2>About CarbonZeroIT</h2>

      <br/><br/>

      <p>CarbonX IT Ltd is a company registered in England and Wales (registration number: 14411406).</p><br/>

      <p>If you then have any questions about how we handle your personal information please contact us by email at <a href="mailto:hello@carbonzeroit.co.uk">hello@carbonzeroit.co.uk</a> .</p><br/>

      <br/><br/>

      <h2>Introduction</h2>

      <br/><br/>

      <p>We are committed to protecting and respecting your privacy. We do this by being completely open and transparent in the way we collect or obtain/ use your personal information.</p><br/>

      <p>For the purposes of EU data protection legislation 2016/679; with all other applicable legislation relating to privacy or data protection, we are a data controller in respect of the information that we collect or obtain about you. This is because we determine why and how your personal information is processed and used.</p><br/>

      <p>The personal information we have collected from you may be shared with fraud prevention agencies who will use it to prevent fraud and money laundering, and to verify your identity. If fraud is detected, you may be refused certain services, finance, or employment. Further details of how your information will be used by us and these fraud prevention agencies, and your data protection rights, can be found in this policy.</p><br/>

      <br/><br/>

      <h2>Personal information we may collect from you and how we obtain it</h2>

      <br/><br/>

      <p>We collect and process various categories of personal information at different stages. This may include:</p><br/>

      <ol className="number">
        <li>
            <p>personal information such as your name, address, date of birth, contact details, visual image and personal appearance,</p>
        </li>
        <li>
            <p>information, including account and transactional information, personal wealth, assets and liabilities, proof of income and expenditure, credit and borrowing history,</p>
        </li>
        <li>
            <p>information about your family and social circumstances,</p>
        </li>
        <li>
            <p>information about your employment details, and</p>
        </li>
        <li>
            <p>identifiers including IP address.</p>
        </li>
      </ol><br/>

      <br/><br/>

      <h2>Information obtained from you</h2>

      <br/><br/>

      <p>When we do need to collect personal data, we may obtain it from you directly in a number of different ways, including:</p><br/>

      <ol className="number" start={6}>
        <li>
            <p>When you provide it online, through our application or by any other method of communication, for example by chatbot</p>
        </li>
        <li>
            <p>particular technical information, including the Internet Protocol (IP) address used to connect your device to the internet, your login information, system and operating system platform type and version, device model, browser or app version, language, location preferences and your location (based on IP address), which may be collected from you when you visit our website</p>
        </li>
      </ol><br/>

      <p>In providing the personal data of any individual (other than yourself) to us during your use of our services, you confirm that you have obtained authority from such individual to disclose their personal data to us, as well as their authority to our collection, use and disclosure of such personal data, for the purposes set out in this privacy notice.</p><br/>

      <br/><br/>

      <h2>Information obtained from third parties</h2>

      <br/><br/>

      <p>If you are applying to us through a third party, then they should have provided you with their own privacy notice in order to tell you (whether online or in person) how they may process your personal information. They should have also told you that credit reference and fraud prevention agency checks will be performed before we consider your application more fully and that your personal information is being shared with us for the purposes and uses set out in this privacy notice. We may also obtain information about you from third parties who provide services to you or us such as credit reference, fraud prevention or government agencies (where permitted by law).</p><br/>

      <p>This Privacy Notice is readily available on our Website and will be updated from time to time.</p><br/>

      <p>Our Website may use “cookies” to enhance your user experience and distinguish you from other users.</p><br/>

      <br/><br/>

      <h2>Why we process your personal information</h2>

      <br/><br/>

      <p>We process your personal information for a variety of reasons, the main types of which and the legal basis for doing so are as follows:</p><br/>

      <ol>
        <li>
            <p>We process your personal information in order to consider and process your mortgage affordability assessment.</p>
        </li>
        <li>
            <p>We will also process your personal information to manage our business operations, for example, our compliance functions, which will include monitoring communications and activities in relation to your account and for accounting and audit purposes. We have legitimate interests in doing so and we may also have legal obligations to fulfil.</p>
        </li>
        <li>
            <p>If we are dealing with a request you have made in order to exercise your legal and regulatory rights (including those referred to in the ‘Your rights under applicable data protection law’) below, this will be done in order to fulfil our legal obligation to respond to you.</p>
        </li>
        <li>
            <p>We process your personal information for marketing purposes. This is necessary in order to fulfil our legitimate interests of providing you with information about products and services that you may be interested in.</p>
        </li>
      </ol><br/>

      <p>If you would like us to delete, block or anonymise information we hold about you, you can contact us outlining what information you would like deleted, blocked or anonymised. To update your preferences, ask us to remove information from our mail lists or make a request to access, update, correct or delete your personal information, please contact us at the following address [contact us email].</p><br/>

      <p>You can at any time tell us not to send you marketing communications by:</p><br/>

      <ul>
        <li>
            <p>Emailing <a href="mailto:hello@carbonzeroit.co.uk" target="_blank">hello@carbonzeroit.co.uk</a></p>
        </li>
        <li>
            <p>Unsubscribing via the unsubscribe link within the marketing emails that you may receive from us.</p>
        </li>
      </ul><br/>

      <p>Any consent that you give us will be as easy to withdraw as it was to give. Withdrawing your consent does not affect the lawfulness of any processing which occurred prior to the withdrawal of consent.</p><br/>
    </div>
  </main>
);

export default Content;