import Header from './../../components/header/header';
import Footer from './../../components/footer/footer';

import './not-found.css';

const NotFound = () => (
  <main>
    <Header/>
    <section className="not-found"><h1>404</h1></section>
    <Footer/>
  </main>
);

export default NotFound;